import React, { useState } from "react";
import { motion } from "framer-motion";
import { useTranslation } from "react-i18next";

import backLogo from "../../Assets/back.jpg";
import house from "../../Assets/house.jpg";
import house2 from "../../Assets/house2.jpg";
import house3 from "../../Assets/house3.jpg";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStaffAction } from "./StaffAction";
import { Link } from "react-router-dom";
import Loading from "../Loading";


const StaffPage = ({ isVisable = false, lang }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation();
  useEffect(() => {
    dispatch(getStaffAction());
  }, []);
  const { staffs,loading } = useSelector((state) => state.getStaff);


  const [images, setImages] = useState([]);

  useEffect(() => {
    if (staffs?.length > 0 && images.length === 0) {
      const newImages = staffs.map((staff) => ({
        image: staff?.image,
        enTitle: staff?.en_title,
        tuTitle: staff?.tu_title,
        krdAbout: staff?.krd_about,
        arAbout: staff?.ar_about,
      }));
      setImages(newImages);
    }
  }, [staffs, images]);

  return (
    <>
      {staffs == undefined || loading ? (
        <Loading/>
      ) : (
        <div className="h-full">
          <motion.h3
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5 }}
            whileHover={{ scale: 1 }}
            className="text-center     text-4xl mt-20 mb-2"
          >
            {t("staff_page.title")}
          </motion.h3>

          <div className="grid grid-cols-4 place-items-center gap-4 justify-items-center px-5">
            {staffs?.map((staff, index) => {
              return (
                <Link to={`/show/staff/${staff?.id}`} className="bg-[#ffd9b4] rounded-md  group ">
                  <motion.img
                    key={index}
                    src={staff?.image}
                    className="object-cover hover:brightness-50   group-hover:brightness-75 duration-500 rounded-md   h-44 w-44 object-top   cursor-pointer"
                    alt=""
                    // onClick={() => openModal(index)}
                    initial={{ opacity: 0, scale: 0.8 }}
                    animate={{ opacity: 1, scale: 1 }}
                    transition={{ duration: 0.5 }}
                    whileHover={{ scale: 1 }}
                  />
                  <p className="text-black  mt-2 p-3 group-hover:underline">
                    {lang == "en" ? staff.en_title : staff.tu_title}
                  </p>
                </Link >
              );
            })}
          </div>

          {/* {isVisable && <Location lang={lang} />} */}
        </div>
      )}
    </>
  );
};

export default StaffPage;
