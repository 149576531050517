import {
  applyMiddleware,
  combineReducers,
  legacy_createStore as createStore,
} from "redux";

import { thunk } from "redux-thunk";
import { getAboutReducer } from "../Components/Pages/About/AboutReducer";
import { getContactReducer } from "../Components/Pages/Location/ContactReducer";
import {
  getStaffReducer,
  showStaffReducer,
} from "../Components/Pages/Staff/StaffReducer";
import { getHomeReducer } from "../Components/Pages/FirstPage/HomeReducer";

// export const FETCH_LANG_SUCCESS = "FETCH_LANG_SUCCESS";

export const FETCH_LANG_SUCCESS = "FETCH_LANG_SUCCESS";

export const langReducer = (
  state = { lang: sessionStorage.getItem("lang") },
  action
) => {
  switch (action.type) {
    case FETCH_LANG_SUCCESS:
      sessionStorage.setItem("lang", action.payload);
      return { ...state, lang: action.payload };

    default:
      return state;
  }
};

const rootReducer = combineReducers({
  getAbout: getAboutReducer,

  getContact: getContactReducer,

  getStaff: getStaffReducer,
  showStaff: showStaffReducer,
  getHome: getHomeReducer,
  langReducer,
});

export const store = createStore(rootReducer, applyMiddleware(thunk));
export default store;
