import React, { useEffect } from "react";
import Sidebar from "./Components/Pages/Bars/Sidebar";
import "./App.css";
import Home from "./Components/Pages/Home/Home";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./Components/Pages/About/About";
import ContactPage from "./Components/Pages/ContactPage/ContactPage";
import StaffPage from "./Components/Pages/Staff/StaffPage";
import Navbar from "./Components/Pages/Bars/Navbar";
import "./i18n";
import { useDispatch, useSelector } from "react-redux";
import ShowStaffPage from "./Components/Pages/Staff/ShowStaffPage";
import i18n from "./i18n";
import { FETCH_LANG_SUCCESS } from "./Store/store";
const App = () => {
  const {lang}=useSelector(state=>state.langReducer)
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log()
    let lang = sessionStorage.getItem("lang");

    sessionStorage.setItem("lang", lang || "kr");
    i18n.changeLanguage(lang == "krd" ? "kr" : lang == "ar" ? "ar" : lang);
    dispatch({
      type: FETCH_LANG_SUCCESS,
      payload: lang,
    });
  }, []);
  return (
    <div className="flex  h-screen  overflow-x-hidden">
      <BrowserRouter>
        <Navbar />
        <Sidebar />
        <div className="  w-full overflow-x-hidden ">
          <Routes>
            <Route path="/" element={<Home lang={lang} />} />
            <Route path="/about" element={<About lang={lang} />} />
            <Route path="/contact" element={<ContactPage visable={true} lang={lang} />} />
            <Route path="/staff" element={<StaffPage isVisable={false} lang={lang} />} />
            <Route path="/show/staff/:id" element={<ShowStaffPage isVisable={false} lang={lang} />} />
          </Routes>
        </div>
      </BrowserRouter>
    </div>
  );
};

export default App;
