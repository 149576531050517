import React from "react";
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";

import About from "../About/About";
import FirstPage from "../FirstPage/FirstPage";
import Location from "../Location/Location";
import ContactPage from "../ContactPage/ContactPage";
import StaffPage from "../Staff/StaffPage";


const Home = ({lang}) => {
  const [firstPageRef, firstPageInView] = useInView();
  const [aboutRef, aboutInView] = useInView();
  const [contactPageRef, contactPageInView] = useInView();
  const [staffPageRef, staffPageInView] = useInView();
  const [locationRef, locationInView] = useInView();

  const animationDuration = 1; // Adjust the duration here

  return (
    <div className=" h-full md:h-screen ">
      <motion.div
        ref={firstPageRef}
        initial={{ opacity: 0, y: 50 }}
        animate={firstPageInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: animationDuration }}
      >
        <FirstPage lang={lang} />
      </motion.div>

      <motion.div
        ref={aboutRef}
        initial={{ opacity: 0, y: 50 }}
        animate={aboutInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: animationDuration }}
      >
        <About lang={lang} />
      </motion.div>

      <motion.div
        ref={staffPageRef}
        initial={{ opacity: 0, y: 50 }}
        animate={staffPageInView ? { opacity: 1, y: 0 } : { opacity: 0, y: 50 }}
        transition={{ duration: animationDuration }}
      >
        <StaffPage lang={lang} isVisible={false} />
      </motion.div>

      <motion.div
        ref={contactPageRef}
        initial={{ opacity: 0, y: -100 }}
        animate={
          contactPageInView ? { opacity: 1, y: 0 } : { opacity: 0, y: -100 }
        }
        transition={{ duration: animationDuration }}
      >
        <ContactPage lang={lang} visible={true} />
      </motion.div>


    </div>
  );
};

export default Home;
