import React from "react";
import { Link } from "react-router-dom";

const FotterPage = ({visable}) => {
  return (
    <div >
      {" "}

      <footer className="  w-full pb-32 md:pb-4 py-4 bg-[#1B1B1B]  text-xl text-[#ffd9b4]  text-center">
        <Link to={"https://www.facebook.com/bryar.jalal?mibextid=ZbWKwL"}>
          Designed By : BJ
        </Link>
      </footer>
    </div>
  );
};

export default FotterPage;
