import React from "react";
import { Link, NavLink } from "react-router-dom";
import otaLogo from "../../Assets/ota.png";
import { useTranslation } from "react-i18next";
import LanguageDropdown from "./LanguageDropdown ";
import './Layout/bars.css'

const Sidebar = () => {
  const { t } = useTranslation();

  const handleScrollToTop = () => {
    window.location.href = '/';
  };

  return (
    <div className="parent-siderbar">
      <Link to="/" onClick={handleScrollToTop}>
        <img
          src={otaLogo}
          alt="OTA"
          className="logo-sidebar"
        />
      </Link>
      <ul className="unorderlist-siderbar">
        <NavLink
          exact
          to="/"
          className={({ isActive }) => (isActive ? "active" : "link")}
        >
          {t('sidebar.home')}
        </NavLink>
        <NavLink
          to="/about"
          className={({ isActive }) => (isActive ? "active" : "link")}
        >
          {t('sidebar.about')}
        </NavLink>
        <NavLink
          to="/staff"
          className={({ isActive }) => (isActive ? "active" : "link")}
        >
          {t('sidebar.staff')}
        </NavLink>
        <NavLink
          to="/contact"
          className={({ isActive }) => (isActive ? "active" : "link")}
        >
          {t('sidebar.contact')}
        </NavLink>
      </ul>
      <LanguageDropdown onClick={() => {}} />
    </div>
  );
};

export default Sidebar;
