import Swal from "sweetalert2";
// import { api } from "../../Api/api";
// import { getToken } from "../Login/Action/login";
import {
  CREATE_STAFF_FAIL,
  CREATE_STAFF_REQUEST,
  CREATE_STAFF_SUCCESS,
  FETCH_STAFF_FAIL,
  FETCH_STAFF_REQUEST,
  FETCH_STAFF_SUCCESS,
  SHOW_STAFF_FAIL,
  SHOW_STAFF_REQUEST,
  SHOW_STAFF_SUCCESS,
  UPDATE_STAFF_FAIL,
  UPDATE_STAFF_REQUEST,
  UPDATE_STAFF_SUCCESS,
} from "./StaffType";
import { getToken } from "../../Login/Action/login";
import { api } from "../../Api/api";

export const createStaffAction =
  (krdTitle, arTitle, krdAbout, arAbout, images) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    const formData = new FormData();
    formData.append("krdTitle", krdTitle);
    formData.append("arTitle", arTitle);
    formData.append("krdAbout", krdAbout);
    formData.append("arAbout", arAbout);
    // formData.append("arTitle", arTitle);
    // console.log(images)
    images.map((image) => {
      // console.log(image)
      formData.append("image[]", image);
    });
    dispatch({
      type: CREATE_STAFF_REQUEST,
      payload: [],
    });
    try {
      const response = await api.post("staff/create", formData, config);
      const { status, data } = response;
      console.log(response);
      if (status == 201) {
        dispatch({
          type: CREATE_STAFF_SUCCESS,
          payload: data,
        });
        dispatch(getStaffAction());
        dispatch(getStaffAction());
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been saved",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (error) {
      //   console.log(error);
      dispatch({
        type: CREATE_STAFF_FAIL,
        payload: [],
      });
    }
  };

export const getStaffAction = () => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  dispatch({
    type: FETCH_STAFF_REQUEST,
    payload: [],
  });
  try {
    const response = await api.get("staff", config);
    const { status, data } = response;
    // console.log(data)
    if (status == 200) {
      dispatch({
        type: FETCH_STAFF_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    //   console.log(error);
    dispatch({
      type: FETCH_STAFF_FAIL,
      payload: [],
    });
  }
};

export const showStaffAction = (id) => async (dispatch) => {
  const config = {
    headers: {
      Authorization: `Bearer ${getToken()}`,
    },
  };

  dispatch({
    type: SHOW_STAFF_REQUEST,
    payload: [],
  });
  try {
    const response = await api.get("staff/show/" + id, config);
    const { status, data } = response;
    // console.log(data)
    if (status == 200) {
      dispatch({
        type: SHOW_STAFF_SUCCESS,
        payload: data,
      });
    }
  } catch (error) {
    //   console.log(error);
    dispatch({
      type: SHOW_STAFF_FAIL,
      payload: [],
    });
  }
};

export const updateStaffAction =
  (id,krdTitle, arTitle, krdAbout, arAbout, image) => async (dispatch) => {
    const config = {
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    };
    const formData = new FormData();
    formData.append("krdTitle", krdTitle);
    formData.append("arTitle", arTitle);
    formData.append("krdAbout", krdAbout);
    formData.append("arAbout", arAbout);
    formData.append("image", image);
    dispatch({
      type: UPDATE_STAFF_REQUEST,
      payload: [],
    });
    try {
      const response = await api.post(`staff/update/${id}`, formData, config);
      const { status, data } = response;

      if (status == 200) {
        dispatch(getStaffAction());
        dispatch(getStaffAction());
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Your work has been updated",
          showConfirmButton: true,
          timer: 1500,
        });
        dispatch({
          type: UPDATE_STAFF_SUCCESS,
          payload: data,
        });
      }
    } catch (error) {
      //   console.log(error);
      dispatch({
        type: UPDATE_STAFF_FAIL,
        payload: [],
      });
    }
  };
