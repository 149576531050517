import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import logo from "../../Assets/ota.png"; // Update with your logo path
import { motion } from "framer-motion";
import { useInView } from "react-intersection-observer";
import { useDispatch, useSelector } from "react-redux";
import { getContactAction } from "./ContactAction";
import Loading from "../Loading";

const Location = () => {
  const { t } = useTranslation();
  const dispatch=useDispatch();
  useEffect(()=>{
    dispatch(getContactAction());
  },[])
  const {contacts,loading}=useSelector(state=>state.getContact);
  return (
    <>
    {
      loading ?<Loading/>:<div className="bg-[#3a5c44]  md:pb-12 font-cali  overflow-hidden text-white place-items-center justify-items-center grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 p-10">
      {/* Left Section: Logo and Company Name */}
      <motion.div
        className="flex items-center space-x-4 overflow-hidden"
        initial={{ opacity: 0, x: -100 }}
        animate={{ opacity: 1, x: 0 }}
        transition={{ duration: 1 }}
      >
        <img src={logo} alt="Vigo Company Logo" className="h-28 md:h-32" />
      </motion.div>

      {/* Center Section: Contact Information */}
      <motion.div
        className="flex flex-col justify-center items-center"
        initial={{ opacity: 0, y: -100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1 }}
      >
        <div className="text-center">
          
          
          <h2 className="text-lg mb-2 mt-4">{t("location.contact")}</h2>
          <p>{contacts[0]?.phone1}</p>
          {/* <p>{t("location.phone2")}</p> */}
          <p>{contacts[0]?.email}</p>
        </div>
      </motion.div>

      {/* Right Section: Google Map */}
      <div className="mt-2 w-full">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d199.18165499234325!2d35.32355200950464!3d36.98816544577755!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x15288f5de13c818b%3A0x9016b4122c28eacb!2sATASOY%20SMMM%20VE%20BA%C4%9EIMSIZ%20DENET%C4%B0M%20B%C3%9CROSU!5e0!3m2!1sen!2siq!4v1722622159489!5m2!1sen!2siq"
          allowfullscreen=""
          width={"100%"}
          className="rounded-md"
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    
    </div>
    }
    
    </>
  );
};

export default Location;
