import React, { useEffect, useState } from "react";
import {
  motion,
  useAnimation,
  useDeprecatedInvertedScale,
} from "framer-motion";
import oivaLogo from "../../Assets/oiva.png";
import ayanLogo from "../../Assets/ayan.png";
import omtaLogo from "../../Assets/omta.png";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import backgroundImage from "../../Assets/background.jpg";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillTelephoneFill, BsTelephone } from "react-icons/bs";
import { useDispatch, useSelector } from "react-redux";
import { getHomeAction } from "./HomeAction";
import Loading from "../Loading";

const FirstPage = () => {
  const controls = useAnimation();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { homes, loading } = useSelector((state) => state.getHome);
  useEffect(() => {
    dispatch(getHomeAction());
  }, []);
  // console.log(homes);
  const container = {
    hidden: { opacity: 1, scale: 0.5 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
        duration: 1, // Duration of the container animation
      },
    },
  };

  const item = {
    hidden: { y: 20, opacity: 1 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 0.5, // Duration of each item animation
      },
    },
  };
  const [hovered, setHovered] = useState("");
  return (
    <>
      {loading ? (
      <Loading/>
      ) : (
        <div
          style={{
            backgroundImage: `url(${backgroundImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          className="h-screen  text-white py-28 overflow-x-hidden"
        >
          <motion.div
            className="h-full z-50  gap-y-40 flex flex-col justify-between"
            initial="hidden"
            animate="visible"
            variants={container}
          >
            <div className="flex   flex-col items-center">
              <motion.h2
                className="text-6xl lg:text-9xl mt-16
              font-normal my-2 text-center  text-[#ffd9b4] " // Ensure no extra space between letters
                variants={item}
                animate={controls}
              >
                {t("roza")}
              </motion.h2>
              <motion.h2
                className="text-2xl lg:text-4xl font-normal     text-center  text-[#F6E96B]" // Ensure no extra space between letters
                variants={item}
                animate={controls}
              >
                YALITIM
              </motion.h2>
            </div>

            <div className="text-center ">
              <motion.div
                className="flex  justify-center  md:flex  md:justify-center gap-4 items-center mt-28  my-10"
                variants={item}
                animate={controls}
              >
                <p className="text-[#F6E96B] font-normal text-xl">
                  {t("contactus")}{" "}
                </p>
                <div className="relative">
                  <HiOutlineMail
                    onMouseEnter={() => setHovered("email")}
                    onMouseLeave={() => setHovered("")}
                    className="text-[#F6E96B] text-xl cursor-pointer"
                  />
                  <p
                    className={`absolute top-6 -left-[5.7rem] md:left-0 mt-2 p-1 bg-[#ffd9b4] text-black rounded transition-all duration-300 transform ${
                      hovered === "email"
                        ? "opacity-100 translate-y-0"
                        : "opacity-0 translate-y-2"
                    }`}
                  >
                    {homes?.length > 0
                      ? homes[0]?.email
                      : "roza91hassan@gmail.com"}
                  </p>
                </div>

                <div className="relative">
                  <BsTelephone
                    onMouseEnter={() => setHovered("phone")}
                    onMouseLeave={() => setHovered("")}
                    className="text-[#F6E96B] text-xl cursor-pointer"
                  />
                  <p
                    className={`absolute top-6 -left-24 md:left-0 mt-2 p-1 bg-[#ffd9b4] text-black rounded transition-all duration-300 transform ${
                      hovered === "phone"
                        ? "opacity-100 translate-y-0"
                        : "opacity-0 translate-y-2"
                    }`}
                  >
                          {homes?.length > 0
                      ? homes[0]?.phone
                      : "+905364956903"}
                    
                  </p>
                </div>
              </motion.div>
            </div>
          </motion.div>
        </div>
      )}
    </>
  );
};

export default FirstPage;
