import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { showStaffAction } from './StaffAction';
import { useParams } from 'react-router-dom';
import Loading from '../Loading';

const ShowStaffPage = ({lang}) => {
    const dispatch=useDispatch();
    const {id}=useParams();
    const {staff,loading}=useSelector(state=>state.showStaff)
    useEffect(()=>{
        dispatch(showStaffAction(id))
    },[])
    // console.log(staff)
  return (
    <>
    {
      loading?<Loading/>:<div className='flex flex-col pb-10'>
      <img src={staff?.image} className='object-top object-cover' alt="" />
      <div className=' mt-10 md:px-20'> 
          <p className='font-bold text-4xl'>{lang=="en"?staff?.en_title:staff?.tu_title}</p>
          <p className=' text-xl mt-3'>{lang=="en"?staff?.en_about:staff?.tu_about}</p>

      </div>
  </div>
    }
    
    </>
  )
}

export default ShowStaffPage